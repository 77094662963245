<template>
    <div>
        <router-link :to="gotodetail" class="image" v-if="eventData.field_image">
            <img :src="newsimg" alt="">
        </router-link>
        <div class="content">
            <p class="date">{{ formatdate + ' - ' + eventData.attributes.field_event_type }}</p>
            <p class="date">{{ formathours }}</p>
            <router-link class="read-more" :to="gotodetail"><h2 class="tile-title">{{ eventData.attributes.title }}</h2></router-link>
            
            <p v-html="eventData.attributes.field_description_w_summary.summary"></p>
            <div class="fulltext" v-html="eventData.attributes.field_description_w_summary.value"></div>
            <router-link class="read-more" :to="gotodetail">{{ 'Read More' }}</router-link>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'news',
    components: {},
    props: {
        eventData: Object,
        routesData: Array
    },
    data: () => {
        return {}
    },
    computed: {
        endpoint (){
            return process.env.VUE_APP_ENDPOINT
        },
        gotodetail() {
            if(this.routesData) {
                var f = this.routesData.filter(rr => this.eventData.attributes.drupal_internal__nid == rr.nid)
                return f[0].path
            } else
                return ""
        },
        newsimg() {
            if(this.eventData.field_image)
                return process.env.VUE_APP_ENDPOINT + this.eventData.field_image.attributes.uri.url
            else 
                return ''
        },
        formatdate() {
            if(this.eventData) {
                var start = new Date(this.eventData.attributes.field_start_date).toLocaleString('en', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })

                var end = new Date(this.eventData.attributes.field_end_date).toLocaleString('en', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })
            
                if(start == end) 
                    return start
                else
                    return 'From ' + start + ' to ' + end
            } else 
                return ''
        },
        formathours() {
            if(this.eventData) {
                var start = new Date(this.eventData.attributes.field_start_date).toLocaleTimeString('en' , { 
                    hour: '2-digit', 
                    minute: '2-digit'
                })

                var end = new Date(this.eventData.attributes.field_end_date).toLocaleTimeString('en' , { 
                    hour: '2-digit', 
                    minute: '2-digit'
                })

                if(start==end)
                    return 'At ' + start
                else
                    return 'Starting at ' + start + ' until ' + end
            } else return ''
        }
    },
    methods: {
        hide(n) {
            this.$refs.event[n].classList.toggle('closed')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/variables.scss";
.closed {
    .fulltext { display: none; }
}

.item {
    border-radius: 5px;
    margin-bottom: 2rem;
    background-color: #fff;

    .content { 
        padding: 2rem 1rem; 
        ::v-deep a:not(:has(h2)) { word-break: break-all; }
    }

    h2 {
        font-size: 1.375rem;
        line-height: 1.7rem;
    }

    p {
        max-width: none;
        &:not(.date) { margin-bottom: 0; }
    }

    .date { color: $orange; margin: 0; }

    .tile-title {
        font-size: 1.375rem;
        line-height: 1.7rem;
        color: #333333;
        transition: all 300ms ease;
        &:hover {
            opacity: .8;
            transition: all 300ms ease;
        }
    }

    .image {
        max-width: 100%;
        height: 250px;
        display: block;
        overflow: hidden;
        
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all 300ms ease;
            -o-object-fit: contain;
            position: absolute;
            object-fit: cover;
        }

        &:hover {
            img {
                transform: scale(1.1);
                transition: all 300ms ease;
            }
        }
    }
}

/* .highlights {
    .item {
        background-color: $darkblue;
        color: #fff;
        ::v-deep p { color: #fff; }
        .date { color: $orange; }
        h2 { color: #fff; }
    }
} */

@media (min-width: 1024px) {
    .item {
            grid-column: span 1;
            .content { padding: 2rem 2rem; }
            h2 {
                font-size: 1.75rem;
                line-height: 2.1875rem;
            }
        }
}
</style>