<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="get involved" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container">
                    <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p>
                </div>
            </section>

            <section class="events main-content">
                <div class="container">
                    <br>
                    <div @click="collapse" :class="['collapsing filter-container', this.collapsed ? 'drop-down' : 'drop-up']">
                        <h3>Filter by category</h3>
                    </div>
                    <div :class="['filters-row', this.collapsed ? 'collapsed' : '']">
                        <button v-for="(c, ind) in typeList" :key="'category'+ind" 
                            :class="[typeFilter.includes(c) ? 'active': '']"
                            @click="toggleFilter(c)">{{ c }}</button>
                    </div>
                </div>

                <div class="container container-items" v-if="this.eventData">
                    <EventTile v-for="(n, index) in this.filteredEvents" :routesData="routesData" :eventData="n"
                        :key="'person'+index" class="item closed" ref="news">
                    </EventTile>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import {
        fetchNodes,
        fetchRoutes,
        fetchSinglePage
    } from '../libs/drupalClient'
    import EventTile from '../components/EventTile.vue'
    export default {
        name: 'events',
        components: {
            HeaderIntroImage,
            EventTile
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "7b831711-8b37-484b-90f4-474cb638c23a",
                eventData: null,
                routesData: null,
                typeFilter: [],
                collapsed: true
            }
        },
        methods: {
            attachment(n) {
                return process.env.VUE_APP_ENDPOINT + n.field_attachment.attributes.uri.url
            },
            hide(n) {
                this.$refs.news[n].classList.toggle('closed')
            },
            logourl(index) {
                return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_images[index].attributes.uri.url
            },
            newsimg(node) {
                var f = this.eventData.filter(p => p.attributes.drupal_internal__nid == node.attributes
                    .drupal_internal__nid)
                if (f[0].field_image)
                    return process.env.VUE_APP_ENDPOINT + f[0].field_image.attributes.uri.url
                else
                    return ''
            },
            formatdate(p) {
                return new Date(p.attributes.field_start_date).toLocaleString('en', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
            },
            collapse() {
                this.collapsed = !this.collapsed
            },
            toggleFilter(c) {
                if(!this.typeFilter.includes(c))
                    this.typeFilter.push(c)
                else 
                    this.typeFilter.splice(this.typeFilter.indexOf(c), 1)
            }
        },
        computed: {
            endpoint() {
                return process.env.VUE_APP_ENDPOINT
            },
            typeList() {
                var tps = []

                if(this.eventData)
                    this.eventData.forEach(e =>  {
                        if(!tps.includes(e.attributes.field_event_type))
                            tps.push(e.attributes.field_event_type)
                    })

                return tps
            },
            filteredEvents() {
                var evs = []
                if(this.eventData) {
                    evs = this.eventData

                    if(this.typeFilter.length) {
                        evs = evs.filter(e => this.typeFilter.includes(e.attributes.field_event_type))
                    }
                }
                return evs
            }
        },
        async mounted() {
            fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text']
                })
                .then(res => {
                    this.pageData = res
                    //console.log('pagedata', res)
                })

            fetchNodes('event', {
                include: ['field_attachment', 'field_image']
            }).then(res => {
                this.eventData = res

                this.eventData = res.sort((a, b) => {
                    return a.attributes.field_start_date > b.attributes.field_start_date ? -1 : 1
                })
                //console.log('all events', this.eventData)
            })

            await fetchRoutes().then(res => {
                //console.log('routes', res)
                this.routesData = res.routes
            })


        }
    }
</script>

<style lang="scss">
@import "../styles/variables.scss";


.events { background-color: #EBF0F5; }
.events .closed { .fulltext { display: none; } }
.events .date {
    margin: 0;
    margin-bottom: 1rem;
}

.events .item {
    border-radius: 5px;
    margin-bottom: 2rem;
    background-color: #fff;
}

.container-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}



@media (min-width: 1024px) {
    .container-items {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 4rem;
    }
    .item {
        grid-column: span 1;
        .content { padding: 2rem 2rem; }
        h2 {
            font-size: 1.75rem;
            line-height: 2.1875rem;
        }
    }
}

    @media (min-width: 1300px) {
        .container-items {
            grid-template-columns: repeat(3, 1fr);
        }
    }
</style>